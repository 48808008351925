<template>
  <div>
    <H5header :type="2"></H5header>
    <div class="top">
      <div class="titlebox">
        <div class="xian"></div>
        <div class="title">选择报名人</div>
      </div>
    </div>
    <div class="box">
      <div class="group">
        <el-radio-group v-model="checkList">
          <div v-for="city in cities" :key="city.id" style="margin-bottom: 10PX;">
            <el-radio :label="city.id">{{ city.title }}</el-radio>
            <div style="margin-top: 3PX;">
              <el-link type="primary" :underline="false" @click="getadd(2, city)">编辑</el-link>
              <el-link type="primary" :underline="false" style="margin-left: 10PX;"
                @click="getdelete(city)">删除</el-link>
            </div>
          </div>
        </el-radio-group>
      </div>
      <div class="but" @click="getadd(1, '')">
        <img src="../../../assets/image/people1.png" alt="">
        <span>新增常用报名人</span>
      </div>
      <div class="button">
        <div class="save" @click="confirm">确定</div>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import Meta from 'vue-meta'
Vue.use(Meta)
import H5header from "../components/header2.vue"
export default {
  components: { H5header },
  props: {},
  data() {
    return {
      checkList: '',
      cities: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    getUserContact() {
      this.$api.getUserContact({ userId: this.$store.state.userid }).then(res => {
        res.data.data.result.forEach(item => {
          item.title = item.name + '，' + (item.sex == 1 ? '男' : '女') + '，' + this.$getUserCard(item.idCard)
        })
        this.cities = res.data.data.result
      })
    },
    getadd(e, row) {
      let data = ''
      if (e == 1) {
        data = row
      } else {
        data = JSON.stringify(row)
      }
      this.$router.push({
        path: '/h5/registernewlyadded',
        query: {
          refresh: new Date().getTime(),
          data: data,
          index: e,
          id: this.$route.query.id,
          groupName: this.$route.query.groupName,
          projectName: this.$route.query.projectName,
        },
      })
    },
    confirm() {
      if (this.$verification(this.checkList)) {
        return this.$message.error('请选择报名人')
      }
      for (let i in this.cities) {
        if (this.cities[i].id == this.checkList) {
          let obj = {
            index: this.index,
            data: this.cities[i]
          }
          let data = JSON.parse(localStorage.getItem('regsignup'))
          data.distinguish = 2
          data.new = obj
          localStorage.setItem('regsignup', JSON.stringify(data));

          this.$router.replace({
            path: '/h5/registerregistrationform',
            query: {
              refresh: new Date().getTime(),
              matchId: data.data.matchId,
              groupName: this.$route.query.groupName,
              projectName: this.$route.query.projectName,
              settingsId: data.data.settingsId,
              projectId: data.data.info[0].projectId,
              projectSettingId: data.data.info[0].projectSettingsId,
              groupSettingId: data.data.info[0].groupSettingsId,
              id: this.$route.query.id
            },
          })

          this.checkList = ''
        }
      }
    },
    getdelete(row) {
      this.$alert('是否确认删除', '提示', {
        confirmButtonText: '确定',
        type: 'warning',
        callback: action => {
          this.$api.removeUserContact({ id: row.id }).then(res => {
            this.$message.success('删除成功')
            this.getUserContact()
          })
        }
      });
    }
  },
  created() {

  },
  mounted() {
    this.getUserContact()
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  activated() { },
}
</script>

<style lang='scss' scoped>
.top {
  display: flex;
  justify-content: space-between;
  margin: 20PX 40PX 0;

  .titlebox {
    display: flex;
    align-items: center;

    .xian {
      width: 5PX;
      height: 15PX;
      background: #3E79CB;
      border-radius: 3PX;
    }

    .title {
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 15PX;
      margin-left: 10PX;
      color: #353333;
      background: linear-gradient(150deg, #2F6BBF 0.146484375%, #7FB5FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.box {
  margin: 0 20PX;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .but {
    width: 100%;
    height: 40PX;
    font-size: 14PX;
    border-radius: 50PX;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to bottom, #6FB2FF, #095FFF);
    cursor: pointer;
    margin-top: 20PX;

    img {
      width: 15PX;
      height: 15PX;
    }

    span {
      margin-left: 10PX;
    }
  }
}

.group {
  width: 100%;
  margin-top: 20PX;
}

::v-deep .el-checkbox-group {
  display: flex;
  flex-direction: column;
}

::v-deep .el-checkbox__label {
  font-size: 15PX;
}

::v-deep .el-checkbox {
  margin-bottom: 15PX;
  margin-right: 0;

}

.group ::v-deep.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #0066FF;
}

.group ::v-deep.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #0066FF;
  background: #0066FF;
}

.button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20PX;

  .save {
    width: 100%;
    height: 40PX;
    background-image: linear-gradient(to bottom, #6FB2FF, #095FFF);
    font-size: 14PX;
    border-radius: 50PX;
    font-weight: bold;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

::v-deep .el-radio-group {
  display: flex;
  flex-direction: column;

  label {
    display: flex;
    align-items: center;
    // margin-bottom: 10PX;

    .el-radio__label {
      font-size: 18PX;
    }
  }
}
</style>